import { State } from "types/store";

export enum WebTimeRuleBookingTypeEnum {
    Booking = 0,
    Takeaway = 1,
    Delivery = 2,
}

export enum WebbookingSettingFlags {
    None = 0,
    displayCustomerName = 1,
}

export enum BookingWindowUnit {
    Months,
    Weeks,
    Days,
}

export enum ChangeStatus {
    unchanged,
    edited,
    created,
    deleted,
}
export interface Section {
    hidden: boolean;
    id: number;
    name: string;
    sortOrder: number;
    unitId: number;
    changeStatus: ChangeStatus;
}
export interface WhiteLabelConfig {
    colors: WhiteLabelColors;
    logo: Image;
    coverImageThirtyTwoByNine: Image;
    coverImageOneByOne: Image;
}

export enum PaymentMethodType {
    CreditCard = 0,
    Invoice = 1,
    Mobile = 6,
    Undefined = 999,
}

export interface Image {
    image: string;
    imageUrl: string;
    imageFilename: string;
    imageUploadData: string;
    cropRatios: CropRatio[];
}
export type CropRatio = {
    id: number;
    imageId: number;
    left: number;
    top: number;
    width: number;
    height: number;
    aspectRatio: string;
};
export interface WebBookingContact {
    name: string;
    fname: string;
    lname: string;
    phone: string;
    mobile: string;
    email: string;
    streetaddress: string;
    zipcode: string;
    city: string;
    allowStoringForever: boolean;
    lastGrab: boolean;
    newsletter: boolean;
    deliveryInfo: string;
    customerName: string;
    invoice: boolean;
    organizationCode: string;
    invoiceStreetAddress: string;
    invoiceEmail: string;
    invoiceMarking: string;
}
export interface WebBooking {
    guid: string;
    unitId: number;
    start: IMoment;
    end: IMoment;
    status: BookingStatus;
    guests: number;
    guestsChildren: number;
    message: string;
    createdDate: IMoment;
    changeDate: IMoment;
    recoupTime: number;
    articles: WebBookingArticle[];
    nonTableArticles: WebBookingArticle[];
    chargeType: ChargeTypeEnum;
    chargeId: string;
    chargeShouldPayAmount: number;
    chargeShouldPayVat: number;
    chargeAuthorizedAmount: number;
    chargePayedAmount: number;
    chargeRefundedAmount: number;
    chargeStatus: number;
    chargePaymentLinkExpirationDate: IMoment;
    contactId: number;
    contact: Contact;
    customer: Customer;
    menus: BookingMenuExternal[];
    isInUpdate: boolean;
    globalBookingNumber: string;
    bookingFlags: BookingFlags;
    language: string;
    cancelAllowedBeforeUtc: IMoment | null;
    webTimeRules: WebTimeRule[] | null;
    history: BookingChange[] | null;
    waitListOffers: WaitListOffer[] | null;
}
export interface WebBookingPaymentTerminalParameters {
    terminalReturnUrl: string;
    contact: WebBookingContact | null;
    customer: WebBookingCustomer | null;
    z: string;
    payMethod: PaymentMethodType;
}
export interface WebBookingCustomer {
    name: string;
    phone: string;
    email: string;
    streetaddress: string;
    zipcode: string;
    city: string;
    orgNr: string;
    invoiceStreetaddress: string;
    invoiceMarking: string;
}

export interface UnitMetaDataExternal {
    activateWebChildren: boolean;
    bookingWindow: number;
    confirmEmail: boolean;
    confirmSms: boolean;
    contactSetting: any;
    cssUrl: string;
    id: number;
    maxWebGuests: number;
    messageCompleted: string;
    messageExceededGuests: string;
    unitId: number;
    name: string;
    colorTheme: string;
    showTimeFilter: boolean;
    binaryFlags: WebbookingSettingFlags;
}

export interface WhiteLabelColors {
    background: string;
    onBackground: string;
    onBackgroundSubdued: string;
    onBackgroundBorder: string;
    backgroundDisabled: string;
    onBackgroundHeader: string;
    surface: string;
    onSurface: string;
    onSurfaceSubdued: string;
    onSurfaceBorder: string;
    surfaceDisabled: string;
    onSurfaceHeader: string;
    primary: string;
    primaryHover: string;
    primaryActive: string;
    onPrimary: string;
    primaryContainer: string;
    onPrimaryContainer: string;
    secondary: string;
    secondaryHover: string;
    secondaryActive: string;
    onSecondary: string;
    secondaryContainer: string;
    onSecondaryContainer: string;
    accent: string;
    accentHover: string;
    accentActive: string;
    accentHoverAlpha: string;
    accentActiveAlpha: string;
    onAccent: string;
    accentContainer: string;
    onAccentContainer: string;
    info: string;
    infoHover: string;
    infoActive: string;
    onInfo: string;
    infoContainer: string;
    onInfoContainer: string;
    success: string;
    successHover: string;
    successActive: string;
    onSuccess: string;
    successContainer: string;
    onSuccessContainer: string;
    warning: string;
    warningHover: string;
    warningActive: string;
    onWarning: string;
    warningContainer: string;
    onWarningContainer: string;
    error: string;
    errorHover: string;
    errorActive: string;
    onError: string;
    errorContainer: string;
    onErrorContainer: string;
    neutral: string;
    neutralHover: string;
    neutralActive: string;
    onNeutral: string;
    neutralContainer: string;
    onNeutralContainer: string;
}
export interface ExternalBookingSettings {
    minGuests: number;
    maxGuests: number;
    openingTime: number;
    closingTime: number;
    companyName: string;
    genericSignature: string;
    maxGuestsInfo: string;
    defaultAmountGuests?: number;
    phoneNumber: string;
    email: string;
    sections: Section[];
    defaultExpandedSectionIds: number[];
    unitsMetaData: UnitMetaDataExternal;
    messageDescription: string;
    messagePUL: string;
    messageBookingClosed: string;
    webConditions: string;
    menuHeader: string;
    menuMessage: string;
    displayMessageBox: boolean;
    displayMenuMessageBox: boolean;
    menuMessageDescription: string;
    gdprInfo: string;
    lastGrab: boolean;
    newsletter: boolean;
    gaTrackingId: string;
    bookingWindowAsMonth: number;
    bookingWindowUnit: BookingWindowUnit;
    paymentMethods: PaymentMethodType[] | null;
    displayCompanyName: boolean;
    enableInvoicing: boolean;
    deliveryInfoDescription: string;
    themeSettings: WhiteLabelConfig;
    address: string;
    disableCancel: boolean;
}

export interface WebBookingUnavailability {
    from?: number;
    to?: number;
    reason: WebBookingUnavailabilityReason;
}
export interface WebBookingTimeRuleUnit {
    ruleId?: number;
    unavailability?: WebBookingUnavailability[];
    disabled?: boolean;
    start: IMoment;
    end: IMoment;
    availableUntil: IMoment;
    availableSeats: number[];
    waitListSeats: number[] | null;
}

export type WebBookingTimeRule = {
    id: number;
    title: string;
    conditions: string;
    paymentInformation: string;
    maximumConcurrentBookings: number;
    maximumConcurrentSeats: number;
    maxGroupSize: number;
    minGroupSize: number;
    lengthMinutes: number;
    recoupTime: number;
    isClosed: boolean;
    validityDate: IMoment;
    date: IMoment;
    arenaEventId: number;
    arenaCrmGroupId: number;
    times: WebBookingTimeRuleUnit[];
    menuGuid: string;
    requireMenuSelection: boolean;
    requireMenuPayment: boolean;
    payment: ConditionRulePaymentEnum;
    paymentModifier: PaymentCalculationMethod;
    paymentAmount: number;
    paymentVAT: number;
    isTextRow: boolean;
    comment: string;
    sectionId: number;
    bookingType: WebTimeRuleBookingTypeEnum;
    timeZone: string;
    forceDeliveryInfo: boolean;
};

type AvailableTimesSection = {
    hidden: number;
    id: number;
    name: string;
    timeSets: WebBookingTimeRule[];
    sortOrder: number;
};
export type AvailableTimesUnit = {
    id: number;
    name: string;
    timeZone: string;
    sections: AvailableTimesSection[];
};

export interface CreateBookingParameters extends State {
    lang?: string;
    isPending?: boolean;
    logMessage?: string;
    message?: string;
    timeSetDate?: Moment;
    times?: {
        ruleId: number;
        start: string;
    }[];
}

export interface PaymentTerminalParameters extends CreateBookingParameters {
    terminalReturnUrl: string;
    payMethod: PaymentMethodType;
}
export interface BackFromPaymentTerminalParameters extends CreateBookingParameters {
    bookingChargeId: string;
}

export interface PaymentEvent {
    id: number;
    type: PaymentEventType;
    transactionDate: IMoment;
}
