import {
    Flex,
    Heading,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

interface MessageProps {
    message: string;
}

export default function BookingMessage({ message }: MessageProps) {
    const { t } = useTranslation();
    return (
        <Flex direction="column" gap={ThemeSpaceVariable.Small}>
            <Heading
                as={"h3"}
                fontSize={ThemeFontSizeVariable.Medium}
                color={ThemeColorVariable.OnSurfaceSubdued}
                fontWeight="medium"
            >
                {t("contact.message")}
            </Heading>
            <Text whiteSpace="pre-line" px={ThemeSpaceVariable.XSmall}>
                {message}
            </Text>
        </Flex>
    );
}
