import { Button, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useSelectedRule } from "hooks/use-selected-rule";
import { useTranslation } from "react-i18next";
import { useBookingStateContext } from "store";
import { ConditionRulePayment as paymentType } from "old/models/webRuleConditionRulePaymentEnum";

interface ISubmitButtonProps {
    isDisabled: boolean;
    isLoading: boolean;
}

const SubmitButton = ({ isDisabled, isLoading }: ISubmitButtonProps) => {
    const { t } = useTranslation();
    const { isWaitList } = useBookingStateContext();
    const selectedRule = useSelectedRule();

    const getButtonText = () => {
        switch (selectedRule?.payment) {
            case paymentType.NoShowFee:
                return t("registerPaymentCard");
            case paymentType.PrePayment:
                return t("payAmount", { amount: selectedRule.prePaymentAmount });
            default:
                return isWaitList ? t("waitList.signUpWaitList") : t("book");
        }
    };
    return (
        <Button
            size="lg"
            variant="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isDisabled}
            w="100%"
            mb={ThemeSpaceVariable.Medium}
        >
            {getButtonText()}
        </Button>
    );
};

export default SubmitButton;
