import { useMutation } from "@tanstack/react-query";
import { post } from "api/apiMethods";
import { BackFromPaymentTerminalParameters, WebBooking } from "hooks/api/types";
import { trackEvent } from "logging/insights";
import { ValidationResult } from "old/common/models/validation/validationResult";
import queryClient from "query-client";
import { useNavigate, useParams } from "react-router-dom";
import { GUID_STORAGE_KEY } from "store";
import { State } from "types/store";

interface ApiResponse {
    result: WebBooking | null;
    validationResult: ValidationResult;
}

export const useBackFromPaymentTerminal = () => {
    const { system } = useParams();
    const navigate = useNavigate();

    const backFromPaymentTerminal = async ({
        state,
        chargeId,
    }: {
        state: State;
        chargeId: string;
    }) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        const data: BackFromPaymentTerminalParameters = {
            ...state,
            logMessage: "Bekräftar betalterminal",
            bookingChargeId: chargeId,
        };

        let eventName = "BackFromPaymentTerminal";
        let webBooking: ApiResponse;

        const startTime = new Date().getTime();

        try {
            webBooking = await post<ApiResponse, BackFromPaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/BackFromPaymentTerminal`,
                data
            );
        } catch (error) {
            eventName = "BackFromPaymentTerminalFailed";
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });

        return webBooking;
    };

    return useMutation({
        mutationFn: backFromPaymentTerminal,
        scope: {
            id: "webbooking-create",
        },
        onSuccess: (data: ApiResponse) => {
            const guid = localStorage.getItem(GUID_STORAGE_KEY);
            queryClient.setQueryData<WebBooking>(["booking", guid], data.result as WebBooking);
            localStorage.removeItem(GUID_STORAGE_KEY);

            navigate(`/booking/${system}/${guid}`, {
                state: { isFinal: true },
            });
        },
    });
};
