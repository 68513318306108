import { ErrorView } from "pages/error-view";
import BookingDetails from "pages/booking-details/booking-details";
import "translations/config/i18n";

import BookingWizard from "pages/booking-wizard";
import { BookingProvider } from "store";
import PaymentConfirmation from "pages/payment-confirmation";

const routes = [
    {
        path: "/",
        errorElement: <ErrorView />,
        element: <BookingProvider />,
        children: [
            {
                path: "/booking/:system/:bookingId",
                element: <BookingDetails />,
            },
            {
                path: "/:system/:unitId",
                element: <BookingWizard />,
            },
            {
                path: "/:system/:unitId/payment-confirmation",
                element: <PaymentConfirmation />,
            },
        ],
    },
];
export default routes;
