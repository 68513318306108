import PaymentSpinner from "components/payment-spinner";
import { useBackFromPaymentTerminal } from "hooks/api/back-from-terminal";
import { useEffect, useRef } from "react";
import { GUID_STORAGE_KEY, useBookingStateContext } from "store";
import { getUrlParams } from "utils";

const PaymentConfirmation = () => {
    const contextState = useBookingStateContext();
    const backFromTerminal = useBackFromPaymentTerminal();
    const { ChargeId, webBookingId } = getUrlParams();

    const hasRunMutate = useRef(null);
    useEffect(() => {
        if (ChargeId && webBookingId && !hasRunMutate.current) {
            hasRunMutate.current = true;
            localStorage.setItem(GUID_STORAGE_KEY, webBookingId);
            backFromTerminal.mutate({ state: contextState, chargeId: ChargeId });
        }
    }, [contextState, backFromTerminal, ChargeId, webBookingId]);

    return <PaymentSpinner />;
};

export default PaymentConfirmation;
