import { WebBookingArticle } from "api/models/webBookingArticle";
import { WebBookingTimeRule } from "hooks/api/types";
import { t } from "i18next";
import { PaymentCalculationMethod } from "types/enums";

export function getPaymentCalculationTotal(
    rule: WebBookingTimeRule,
    guests: number,
    resourceArticles: WebBookingArticle[]
): number {
    switch (rule.paymentModifier) {
        case PaymentCalculationMethod.PaymentPerBooking:
            return rule.paymentAmount;
        case PaymentCalculationMethod.PaymentPerGuest:
            return rule.paymentAmount * guests;
        case PaymentCalculationMethod.PaymentPerResource:
            return rule.paymentAmount * (resourceArticles?.length || 1);
        default:
            return rule.paymentAmount;
    }
}

export function getPaymentUnitTranslation(rule: WebBookingTimeRule): string {
    switch (rule.paymentModifier) {
        case PaymentCalculationMethod.PaymentPerBooking:
            return t("payment.unit.booking");
        case PaymentCalculationMethod.PaymentPerGuest:
            return t("payment.unit.guest");
        case PaymentCalculationMethod.PaymentPerResource:
            return t("payment.unit.resource");
        default:
            return t("payment.unit.guest");
    }
}
