import { useMutation } from "@tanstack/react-query";
import { post } from "api/apiMethods";
import { PaymentTerminalParameters } from "hooks/api/types";
import { trackEvent } from "logging/insights";
import { GUID_STORAGE_KEY } from "store";
import i18n from "translations/config/i18n";
import { State } from "types/store";

export const usePaymentTerminal = () => {
    const paymentTerminal = async (state: State) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        const returnUrl = `${document.location.origin + document.location.pathname}/payment-confirmation${document.location.search}`;
        const data: PaymentTerminalParameters = {
            ...state,
            lang: i18n.language,
            logMessage: "Betalning påbörjad",
            message: state.contact?.message,
            terminalReturnUrl: returnUrl,
            payMethod: null,
        };

        let eventName = "PaymentTerminal";
        let terminalUrl: string;

        const startTime = new Date().getTime();
        try {
            terminalUrl = await post<string, PaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/PaymentTerminal`,
                data
            );
        } catch (error) {
            eventName = "PaymentTerminalFailed";
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });

        return terminalUrl;
    };

    return useMutation({
        mutationFn: paymentTerminal,
        scope: {
            id: "webbooking-create",
        },
        onSuccess: (data) => {
            window.location.replace(data);
        },
    });
};
