import { Box, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import Price from "components/price";
import { useWebBooking } from "hooks";
import { useSelectedRule } from "hooks/use-selected-rule";
import { t } from "i18next";
import { getPaymentCalculationTotal, getPaymentUnitTranslation } from "utils/payment-utils";
import { BookingSummaryItem } from "./booking-summary-list-item";
import ChargeStatusEnum from "old/models/chargeStatusEnum";

const CardRegistrationInformation = () => {
    const { data: webBooking } = useWebBooking();
    const selectedRule = useSelectedRule();

    if (!selectedRule) return null;

    const totalValue = getPaymentCalculationTotal(
        selectedRule,
        webBooking.guests,
        webBooking?.articles?.toArray().filter((x) => x.isTable)
    );

    return (
        <Box>
            <Text fontWeight="medium">{t("payment.paymentCardRegistration")}</Text>
            <Stack gap={ThemeSpaceVariable.XSmall} mt={ThemeSpaceVariable.Small} alignItems="start">
                <BookingSummaryItem
                    label={t("payment.paymentCardRegistered")}
                    value={
                        webBooking.chargeStatus === ChargeStatusEnum.CardRegistered
                            ? t("yes")
                            : t("no")
                    }
                />
                <BookingSummaryItem label={t("payment.noShowFee")}>
                    <Price
                        amount={totalValue}
                        display="symbol"
                        color={ThemeColorVariable.OnSurfaceSubdued}
                    />
                </BookingSummaryItem>
                <BookingSummaryItem
                    label=""
                    value={
                        <Text color={ThemeColorVariable.OnSurfaceSubdued}>
                            (
                            <Price
                                amount={selectedRule.paymentAmount}
                                display="symbol"
                                color={ThemeColorVariable.OnSurfaceSubdued}
                            />{" "}
                            / {getPaymentUnitTranslation(selectedRule)}
                        </Text>
                    }
                />
            </Stack>
        </Box>
    );
};

export default CardRegistrationInformation;
