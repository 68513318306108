import { Alert, ThemeSpaceVariable } from "caspeco-casper-ui";
import AlertSkeleton from "components/skeletons/alert-skeleton";
import { useAvailableTimes } from "hooks/api";
import { useReservation } from "hooks/api/create-reservation";
import { useSelectedRule } from "hooks/use-selected-rule";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBookingStateContext, useBookingActionsContext } from "store";
import { CurrencyDisplay } from "translations/currency";
import { getPaymentCalculationTotal, getPaymentUnitTranslation } from "utils/payment-utils";

const PrePaymentAlert = () => {
    const { t } = useTranslation();
    const selectedRule = useSelectedRule();
    const state = useBookingStateContext();
    const { reservation: reservationMutation } = useBookingActionsContext();

    const availableTimes = useAvailableTimes(state.date);

    const reservation = useReservation();

    if (availableTimes.isPending || reservationMutation.isPending || reservation.isPending)
        return <AlertSkeleton />;

    const value = getPaymentCalculationTotal(
        selectedRule,
        state.guests,
        reservation.data?.articles?.filter((x) => x.isTable)
    );

    return (
        <Alert type="info" iconVerticalAlign="flex-start" my={ThemeSpaceVariable.Large}>
            {t("prePaymentInfoAlert", {
                totalValue: value,
                unitAmount: selectedRule.paymentAmount,
                display: CurrencyDisplay.Symbol,
                unit: getPaymentUnitTranslation(selectedRule),
            })}
        </Alert>
    );
};

export default PrePaymentAlert;
