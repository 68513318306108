import {
    Box,
    Heading,
    Icon,
    Icons,
    Stack,
    Text,
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

export function BookingSuccess() {
    const { t } = useTranslation();

    const border = `${ThemeBorderVariable.Small} ${ThemeColorVariable.Success}`;
    return (
        <Stack alignItems="center">
            <Box
                backgroundColor={ThemeColorVariable.SuccessContainer}
                border={border}
                padding="10px"
                borderRadius="50%"
                marginBottom="16px"
            >
                <Icon icon={Icons.Success} fontSize="40px" color={ThemeColorVariable.Success} />
            </Box>
            <Heading as="h1" maxW="340" fontWeight="500" textAlign="center">
                {t("thanks")}
            </Heading>

            <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                {t("bookingSuccessMessage")}
            </Text>
        </Stack>
    );
}
