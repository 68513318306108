import { Box, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useWebBooking } from "hooks";
import { t } from "i18next";
import { BookingSummaryItem } from "./booking-summary-list-item";
import Price from "components/price";
import { PaymentEventType } from "types/enums";

const PaymentInformation = () => {
    const { data: webBooking } = useWebBooking();

    const charge = webBooking.paymentEvents.find(
        (x) => x.type === PaymentEventType.WebBookingCharge
    );
    const formattedTransactionDate = charge?.transactionDate.format("YYYY-MM-DD HH:mm");
    return (
        <Box>
            <Text fontWeight="medium">{t("payment.prePayment")}</Text>
            <Stack gap={ThemeSpaceVariable.XSmall} mt={ThemeSpaceVariable.Small} alignItems="start">
                <BookingSummaryItem label={t("payment.payed")}>
                    <Price
                        amount={webBooking?.chargePayedAmount}
                        display="symbol"
                        color={ThemeColorVariable.OnSurfaceSubdued}
                    />
                </BookingSummaryItem>
                <BookingSummaryItem label="" value={formattedTransactionDate} />
            </Stack>
        </Box>
    );
};

export default PaymentInformation;
