import { Box } from "caspeco-casper-ui";
import ReservationFailedModal from "components/error-components/reservation-failed-modal";
import {
    ContactInformation,
    EditSelectionButtonGroup,
    SelectChildrenAmount,
    SelectDate,
    SelectGuestsAmount,
    SelectTime,
} from "components/selection-components";
import Spinner from "components/spinner/spinner";
import { useError } from "context/error-context";
import { useDayStates, useExternalBookingSettings } from "hooks/api";
import { context as BookingViewContext } from "hooks/bookingView";
import { Reset } from "old/actions/webBookingViewActions";
import { IsoCountryCode } from "old/application/models/management/user";
import { default as webBookingViewStore } from "old/stores/webBookingViewStore";
import { ErrorView } from "pages/error-view";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUrlParams } from "utils";
import { useBookingStateContext } from "../store";

import "global.less";

window.addEventListener("popstate", () => {
    new Reset();
});
const isInitialized = false;
async function init() {
    if (isInitialized) return;

    const { system } = getUrlParams();

    const systemParts = system?.split("_");
    const [SYSTEM_PREFIX] = systemParts ?? [];

    if (
        SYSTEM_PREFIX &&
        !Object.values(IsoCountryCode).includes(SYSTEM_PREFIX.toLocaleUpperCase())
    ) {
        // Antiviruses scanning emails, ROT-13 encrypts system- and other GET-parameters resulting in spammy requests to MARC
        throw new Error(`Invalid system prefix in: ${system}`);
    }
}
init();
export default function BookingWizard() {
    const [state, setState] = useState(webBookingViewStore.getState());
    const externalBookingSettings = useExternalBookingSettings();

    const { system, unitId } = useParams();
    const { error, isLoading } = useError();

    const [selectedMonth, setSelectedMonth] = useState(new Date());

    useDayStates(selectedMonth);

    useEffect(() => {
        const onStoreChange = () => {
            setState(webBookingViewStore.getState());
        };
        webBookingViewStore.onChange(onStoreChange);
        return () => {
            webBookingViewStore.offChange(onStoreChange);
        };
    }, []);

    useEffect(() => {
        if (system && unitId) {
            localStorage.setItem("lastKnownSystemId", system);
            localStorage.setItem("lastKnownUnitId", unitId);
        }
    }, [system, unitId]);

    const { guests, guestsChildren, date, times } = useBookingStateContext();

    if (isLoading || externalBookingSettings.isLoading) {
        return <Spinner />;
    }

    if (error || externalBookingSettings.isError) {
        return <ErrorView />;
    }
    const { activateWebChildren, maxWebGuests } = externalBookingSettings.data.unitsMetaData;

    return (
        <Box className="webBookingContainer" flex={1}>
            <BookingViewContext.Provider value={state}>
                <ReservationFailedModal />
                <EditSelectionButtonGroup />
                {!guests || guests > maxWebGuests ? (
                    <SelectGuestsAmount />
                ) : activateWebChildren && guestsChildren === null ? (
                    <SelectChildrenAmount />
                ) : !date ? (
                    <SelectDate selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
                ) : !times ? (
                    <SelectTime />
                ) : (
                    <ContactInformation />
                )}
            </BookingViewContext.Provider>
        </Box>
    );
}
